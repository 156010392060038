import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import NotifyController from '../utilities/Toast';
import { readToken } from './LocalStorageService';

export const httpApi = axios.create({
    baseURL: (process.env.REACT_APP_BE ?? ""),
});

httpApi.interceptors.request.use((config) => {
    config.headers = { ...config.headers, Authorization: `Bearer ${readToken()}` };
    return config;
});

// Add a response interceptor to handle errors
httpApi.interceptors.response.use(
    response => response,
    error => {
        checkHttpStatus(error.response);
        return Promise.reject(error);
    }
);

export interface ApiErrorData {
    message: string;
}

export const httpGet = async (url: string, config?: AxiosRequestConfig) => {
    const result = await httpApi.get(url, config)
    return checkHttpStatus(result)
}

export const httpPost = async (url: string, body: any, config?: AxiosRequestConfig) => {
    const result = await httpApi.post(url, body, config)
    return checkHttpStatus(result)
}

export const httpPut = async (url: string, body: any, config?: AxiosRequestConfig) => {
    const result = await httpApi.put(url, body, config)
    return checkHttpStatus(result)
}

export const httpDelete = async (url: string, config?: AxiosRequestConfig) => {
    const result = await httpApi.delete(url, config)
    return checkHttpStatus(result)
}

export const checkHttpStatus = (result: AxiosResponse<any>) => {
    if (result.status < 200 || result.status >= 400) {
        NotifyController.warning(result.data.message);
        // if (result.status === 401) {
        //     deleteToken();
        //     window.location.href = '/login';
        // }
        if (result.status === 403) {
            window.alert('You do not have permission to access this page');
            window.location.href = '/';
        }
        if (result.status >= 500) {
            window.location.href = '../error';
        }
        return { data: {} } as any;
    }
    return result;
}
